import '@styles';
import { MDCRipple } from '@material/ripple';
import { TsxMdcComponent } from '@models';
// bounded ripples originate from the point of click
// unbounded ripples originate from the center of the element
// icon buttons must have unbounded ripples
const fixIconButtonRipples = (buttons) => {
    buttons.forEach((ref) => {
        if (ref.nativeElement.classList.contains('mdc-icon-button')) {
            const button = ref.component;
            button.unbounded = true;
        }
    });
};
(() => {
    const thisWindow = window;
    thisWindow.tsxMdcComponentsInitButtons = () => {
        const ripples = new TsxMdcComponent('.mdc-button, .mdc-icon-button', MDCRipple);
        fixIconButtonRipples(ripples.components);
    };
    thisWindow.tsxMdcComponentsInitButtons();
})();
